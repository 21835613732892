@charset "utf-8";
/* CSS Document */

body{ background:url(https://cdn.shopify.com/s/files/1/0719/8415/2872/files/img_bg.jpg); }





table.b_2{ width:100%;}
.b_2 td{ width:49%;}
.b_2 th{ width:1%;}


.header_01_top .inner_01,
.header_01_sub .inner_01{
	width:1366px;
	margin:0 auto;
}

@media screen and (max-width: 480px) {
.header_01_top .inner_01,
.header_01_sub .inner_01{ width:100%;
}
}


/* ページ内リンク位置調整*/
#c_top,
#cart{
    display: block;
    padding-top: 150px;
    margin-top: -150px;
}


/* +++++++++++++++++++++++++++++++++++++++ */
/* + 旧コード調整用　共通説明文など +++++ */
/* +++++++++++++++++++++++++++++++++++++++ */






/* LP下挿入画像
span.sale_desc {
    display: block;
    padding: 0 0 900px 0; 
    background: url(../01_img/lp/haisou_line.jpg) no-repeat 0 99.6%;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
}
*/

a:hover,
a img:hover{
	filter:alpha(style=0, opacity=90);
	-moz-opacity:0.9;
	opacity:0.9;
}

iframe{
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-size:100%;
}

.inner_01{
	width:1200px;
	margin:0 auto;
}

.inner_01 iframe.side_01{
	display:block;
	float:left;
}
.inner_01 iframe.top_01{
	display:block;
	float:right;
}


/* +++++++++++++++++++++++++++++++++++++++ */
/* + iframe ++++++++++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++++ */
iframe.header_01{
	width:100%;
	height:900px;/*  */
}
iframe.h_slide_01{
	width:100%;
	height:550px;/*  */
	margin:20px auto;
}

.header_01_top .inner_01,
.header_01_sub .inner_01{
	position:relative;
}
iframe.h_search_01{
	width:170px;
	height:31px;
	border-bottom:#fff 1px solid;
	margin:38px 0 0 5px;

}
.header_01_top iframe.h_search_01{
	top:89px;
	right:12%;
}
.header_01_sub iframe.h_search_01{
	top:74px;
	right:52%;
}


iframe.top_01{
	width:100%;
	height:10320px; /* 10320 9500　10540 */
	margin:0px 0 0 0;
}



iframe.footer_01{
	width:100%;
	height:90px;

	margin:4px 0 0 0;

}
/* ipad　右スペース削除用　*/
@media screen and (max-width: 1200px) {
#menu_01,
iframe.header_01,
iframe.header_01_sub,
iframe.h_slide_01,
iframe.top_01,
iframe.h_event,
iframe.footer_01{ width:1200px!important;}

.inner_01{width:1100px;}
iframe.top_01{ height:9900px;}

}
@media screen and (max-width: 767px) {
iframe.header_01,
iframe.header_01_sub,
iframe.h_slide_01,
iframe.top_01,
iframe.h_event,
iframe.footer_01{ width:100%!important;}
}


@media screen and (max-width: 480px) {
.inner_01{ width:100%;}
}


/* +++++++++++++++++++++++++++++++++++++++ */
/* + レイアウト用 ++++++++++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++++ */






/* +++++++++++++++++++++++++++++++++++++ */
/* ++++  search  +++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++ */

/* header */

#search1 {
	width:100%;
    position: relative; 
	margin:0 0 10px 0;
}  
#keywords1{  
    width: 100%;
    height: 25px;  
    background: none; 
	font-size:18px;
    color: #fff; 
    border:none;  

/*    border-bottom:#c2c1c0 1px solid;  */
    padding: 4px 50px 4px 10px;  

}
#header_search input[type="button"],input[type="text"],input[type="submit"],input[type="image"],textarea{
    outline: none;
}

input#keywords1::placeholder{
    color:#fff;
	font-weight:bold;
}
/*　フォーカス時placeholder文字削除　*/
#header_search input#keywords1:focus::-webkit-input-placeholder {color: transparent;}
#header_search input#keywords1:focus::-moz-placeholder {color: transparent;}
#header_search input#keywords1:focus::-ms-input-placeholder {color: transparent;}
#header_search input#keywords1:focus::placeholder {color: transparent;}


#searchbutton1 {  
    position:absolute;  
    top:0px;  
    _top:0px;  
    right:0px;  
}  
#searchbutton2 {
	margin:10px 0 0 0;
}  
*:first-child + html #searchbutton1    {  
    top: 0px;
}  



/* side */

#side_search #search1 {
	width:100%;
    position: relative; 
	margin:0 0 10px 0;
}  
#side_search #keywords1{  
    width: 100%;
    height: 24px;  
    background: #eee; 
    color: #666; 
    border:none;  

    padding: 4px 50px 4px 10px;  

}  

#side_search input#keywords1::placeholder{
    color: #666; 
}

#side_search #searchbutton1 {  
    position:absolute;  
    top:0px;  
    _top:0px;  
    right:0px;  
}  
#side_search #searchbutton2 {
	margin:10px 0 0 0;
}  
*:first-child + html #side_search #searchbutton1    {  
    top: 1px;
}  




/* +++++++++++++++++++++++++++++++++++++++ */
/* + news ++++++++++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++++ */

iframe.news_01{
	width:100%;
	height:130px;

}


.news_01{
}
#news_01{
	margin:0 auto;
}

#news_01 div{
	width:100%;
	display:block;
	padding:0;
	background:url(../01_img/top/03_news/line_01.jpg) no-repeat 0% 100%;
}
#news_01 dl{
	width:100%;
	display:block;
	padding:0;
}

#news_01 dt{
	width:15%;
	font-size:16px;
	line-height:21px;
	float:left;
	color:#cfaa76;
	padding: 0 0 15px 0;
	margin:0;
	border-bottom:#cfaa76 1px solid;
}


#news_01 dd{
	width:85%;
	float:left;
	color:#5e3a22;
	font-size:16px;
	line-height:21px;
/*	background:url(../01_img/top/03_news/arrow.jpg) no-repeat 100% 15%;*/
	padding:0 0 15px 0;
	margin:0;
	border-bottom:#cfaa76 1px solid;
}
#news_01 a{
	color:#5e3a22;
/*	
	color:#2268e2;
	text-decoration:none;
*/
}


@media screen and (max-width: 414px){
#news_01 dt.br_sp br{ display:none;}

#news_01 dt{ width:100%; border-bottom:none; padding:0 0 5px 0;}
#news_01 dd{ width:100%; background:none;}

}






/* +++++++++++++++++++++++ */
/* pc sp切替　*/
/* +++++++++++++++++++++++ */

.pc{ display:block;}
.sp{ display:none;}

@media screen and (max-width: 480px) {
.pc{ display:none!important;}
.sp{ display:block!important;}
.event_01{width: 100%!important;}
}






